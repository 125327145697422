





























import {
  defineComponent,
  computed
} from '@nuxtjs/composition-api'
import { SfButton, SfImage } from '@storefront-ui/vue'
import { useImage } from '@wemade-vsf/composables'
import { bannerProps, useCmsBanner } from '@wemade-vsf/composables';

export default defineComponent({
  name: 'ImageBanner',
  props: {
    appearance: {
      type: String,
      default: 'default'
    },
    buttontext: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    ...bannerProps
  },
  components: {
    SfButton,
    SfImage
  },
  setup (props) {
    const { getComponentClasses, isNewTab, isExternal, magentoImage } = useCmsBanner(props)
    const { getMagentoImage } = useImage()
    const componentType = computed(() => {
      if (props.appearance === 'button') return 'div'
      return isExternal ? 'a' : 'nuxt-link'
    })
    const componentClasses = computed(() => {
      return {
        'has-cta': props.appearance === 'button',
        ...getComponentClasses()
      }
    })
    const componentAttributes = computed(() => {
      let atts = {}
      if (props.appearance === 'button') return atts
      atts[isExternal ? 'href' : 'to'] = props.link
      atts['target'] = isNewTab ? '_blank' : '_self'
      return atts
    })
    const showArrow = computed(() => ['default', 'arrow'].includes(props.appearance))

    const sizes = computed(() => {
      let size = 'sm:100vw';
      if (props.width) {
        size = size + ' md:' + props.width + 'px';
      } else {
        size = size + ' md:50vw';
      }
      return size;
    })

    return {
      componentAttributes,
      componentClasses,
      componentType,
      getMagentoImage,
      magentoImage,
      showArrow,
      sizes
    }
  }
})
